import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DrupalSVG from "../images/Drupal_EL_white_RGB.svg"
import GatsbySVG from "../images/Gatsby_Monogram_White.svg"

const Footer = () => {
  return (
    <footer>
        <div
          className="container"
        >
          <div className="row">
            <div className="footer__copyright col-12 col-md-6">
              &copy;{new Date().getFullYear()} Chris Welchhans
              <p className="footer__disclaimer">All opinions expressed on this site are my own.</p>
              <section className="footer__credit">
                <p>This site was built with <a href="https://drupal.org" title="Drupal - Open-source CMS" target="drupal">Drupal</a> and <a href="https://www.gatsbyjs.com/" title="Gatsby - The fastest frontend framework for headless CMS's" target="gatsby">Gatsby</a>.</p>
                <div>
                  <div className="footer__credit__icon">
                    <a href="https://drupal.org" title="Drupal - Open-source CMS" target="drupal">
                      <img src={DrupalSVG} alt="Drupal white logo" />
                    </a>
                  </div>
                  <div className="footer__credit__icon">
                    <a href="https://www.gatsbyjs.com/" title="Gatsby - The fastest frontend framework for headless CMS's" target="gatsby">
                      <img src={GatsbySVG} alt="Gatsby white logo" />
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div className="footer__links col-12 col-md-6">
              <a href="mailto:chris@welchhans.com" title="Email me: chris@welchhans.com">
                <FontAwesomeIcon className="fa-icon" icon={["fas", "envelope"]} />
              </a>
              <a href="https://www.linkedin.com/in/chriswelchhans" title="Connect with me on LinkedIn" target="linked-in">
                <FontAwesomeIcon className="fa-icon" icon={["fab", "linkedin-in"]} />
              </a>
              <a href="http://chris.welchhans.com" title="Get to know me on my personal website" target="chris">
                <FontAwesomeIcon className="fa-icon" icon={["fas", "globe"]} />
              </a>
              {/* <a href="https://twitter.com/ChrisWelchhans" title="Waste time with me on Twitter" target="twitter">
                <FontAwesomeIcon className="fa-icon" icon={["fab", "twitter"]} />
              </a> */}
            </div>
          </div>
        </div>
    </footer>
  )
}

export default Footer
